import React from 'react'
import styled from 'styled-components'
import { Avatar } from '../../atoms/Avatar'
import { Reviews } from '../../molecules/Reviews'
import { Text, Title, size } from '../../../youtalk-storybook/src/ui'
import { getExperienceWithText, getYearsWithText } from '../../atoms/ageParser'

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const ProfessionWithName = styled.div``

export const AvatarBlock = styled(
  ({ psychologist, onReviewsClick, className }) => {
    const professions = (psychologist.professions || [])
      .map(({ profession_name }, index) =>
        index === 0 ? profession_name : profession_name.toLowerCase()
      )
      .join(', ')

    const age = getYearsWithText(
      psychologist.birthday_date ?? psychologist.birthdayDate
    )

    const experience = getExperienceWithText(psychologist.start_of_practice)

    const reviewsCount = psychologist.reviews?.length ?? 0

    return (
      <div className={className}>
        <Avatar
          alt="logo"
          photoUrl={psychologist?.photoUrl}
          size="large"
          source="profile"
          videoURL={psychologist?.video_url}
        />
        <TextBlock>
          <ProfessionWithName>
            <Text.Medium semiBold>{professions}</Text.Medium>
            <Title.H3>{psychologist.name}</Title.H3>
          </ProfessionWithName>
          <Text.Medium semiBold>
            {age} · опыт {experience}
          </Text.Medium>
          {reviewsCount > 0 && (
            <Reviews
              onReviewsClick={() => onReviewsClick()}
              reviewsCount={reviewsCount}
            />
          )}
        </TextBlock>
      </div>
    )
  }
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;

  ${Title.H3} {
    @media (max-width: ${size.sm}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  ${Avatar} {
    @media (max-width: ${size.sm}) {
      height: 100px;
      width: 100px;
    }
  }
`
