/* eslint-disable react-func/max-lines-per-function */
import { Event as GAEvent } from '../../../../components/GA'
import { RegistrationErrors } from '../vars'
import { amplitudeIdentifyRegistrationDate } from '../../../../components/amplitude/identify'
import { authCarrot } from '../../../../api'
import { trackCodeWrong } from '../../../../components/amplitude/auth/trackCodeWrong'
import { useConfirmRegistrationMutation } from '../../query'
import { useErrorModalContext } from '../../hooks/useErrorProvider'
import { useRegisterResult } from '../../../../atoms/useRegisterResult'
import { useRegistrationModalContext } from './useRegistrationModalContext'

export const useCodeStepSubmit = () => {
  const [secondStepRegisterMutation] = useConfirmRegistrationMutation()
  const context = useRegistrationModalContext()
  const errorContext = useErrorModalContext()
  const { setRegisterResult } = useRegisterResult()
  return async (values) => {
    try {
      const response = await secondStepRegisterMutation({
        variables: {
          input: {
            code: values.code,
            token: context.formData.token
          }
        }
      })

      if (response.data.confirmRegistration?.code) {
        const code = response.data.confirmRegistration.code
        if (code === RegistrationErrors.USER_EXISTS) {
          errorContext.onIdentityTakenError()
          return
        }
        if (code === RegistrationErrors.INCOMPLETE_SESSION_DATA) {
          errorContext.onServerError()
          return
        }
        if (code === RegistrationErrors.INCORRECT_CODE) {
          trackCodeWrong()
          return { code: response.data.confirmRegistration.message }
        }
        if (code === RegistrationErrors.NO_SLOT_AVAILABLE) {
          return { code: response.data.confirmRegistration.message }
        }
      }

      if (response.data.confirmRegistration.jwt) {
        setRegisterResult({
          jwt: response.data.confirmRegistration.jwt,
          role: response.data.confirmRegistration.role,
          id: response.data.confirmRegistration.id,
          hadAnyRelationships:
            response.data.confirmRegistration.hadAnyRelationships,
          newPsychologistId: response.data.confirmRegistration.newPsychologistId
        })
        context.updateContext({ loading: true })

        amplitudeIdentifyRegistrationDate()
        await authCarrot(response.data.confirmRegistration.id)
        GAEvent.registrationOk('from_registration')
        // eslint-disable-next-line no-restricted-globals
        location.href = process.env.GATSBY_LK_URL
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      errorContext.onServerError()
    }
  }
}
