export const RegistrationStep = {
  MEET: 'MEET',
  IDENTITY: 'IDENTITY',
  CODE: 'CODE'
}
export const LOGIN_RESEND_TIME = 60
export const SESSION_STORAGE_KEY = 'auth'

export const RegistrationErrors = {
  USER_EXISTS: 'USER_EXISTS',
  INCORRECT_CODE: 'INCORRECT_CODE',
  NO_SLOT_AVAILABLE: 'NO_SLOT_AVAILABLE'
}

export const REGISTRATION_FORM_STORAGE_KEY = 'registration_form_data'
export const CATALOG_REGISTRATION_FORM_STORAGE_KEY =
  'catalog_registration_form_data'
export const AUTH_RESULT = 'auth'

export const subscription = {
  errors: true,
  hasSubmitErrors: true,
  hasValidationErrors: true,
  invalid: true,
  pristine: true,
  submitting: true,
  values: true
}
