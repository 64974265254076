import qs from 'qs'
import { PT_SOURCE_QUERY_PARAM } from '../CatalogContextProvider/hooks/useQuerySave'
import { useCheckIsWizard } from '../../hooks/useCheckIsWizard'
import { useLocalStorage } from '../../../youtalk-storybook/src/ui'
import { useLocation } from '@reach/router'

export const WIZARD = 'wizard'
export const CATALOG = 'catalog'

export const usePsyCardStorage = () => {
  const isWizard = useCheckIsWizard()
  const { search } = useLocation()
  const searchObj = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  return useLocalStorage(
    searchObj[PT_SOURCE_QUERY_PARAM] || (isWizard ? WIZARD : CATALOG)
  )
}
