import { gql, useQuery } from '@apollo/client'

const GET_PSYCHOLOGIST = gql`
  query psychologistsCatalogItem($id: String!) {
    psychologistsCatalogItem(id: $id) {
      id
      name
      birthdayDate
      english
      workApproaches {
        name
        id
      }
      nonWorkingAreas {
        name
        id
      }
      professions {
        professionName
        id
      }
      photoUrl
      reviews {
        id
        clientAge
        clientName
        text
        tags
      }
      isWorkingWithTeenagerPatients
    }
  }
`
export const useGetPsychologist = (input) =>
  useQuery(GET_PSYCHOLOGIST, {
    fetchPolicy: 'network-only',
    pollInterval: 1000 * 60 * 10, //10 min autorefetch,
    ...input
  })
