/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import { Age } from '../../../Form/Age'
import { CATALOG } from '../../../Information/usePsyCardStorage'
import { CatalogFieldNamesEnum } from '../../../CatalogContextProvider/enums'
import {
  ControlTextArea,
  useLocalStorage,
  usePopup,
  useSessionStorage
} from '../../../../../youtalk-storybook/src/ui'
import { Form as FinalForm } from 'react-final-form'
import { FormButton } from '../../FormButton'
import {
  FormHeader,
  FormItems,
  FormMain,
  FormWrapWithoutButton,
  ModalButtonGroup,
  Paragraph,
  Title
} from '../../index.styles'
import { INITIAL_CATALOG_FILTERS } from '../../../CatalogContextProvider/vars'
import { Name } from '../../../Form/Input'
import { PSYCHOLOGIST_WORKS_WITH_TEENS } from '../../../../constants'
import { TeenagerPopup } from '../../../CatalogFiltersModal/Fields/TeenagerPopup'
import { getCatalogQuery } from '../../../CatalogContextProvider/hooks/useQuerySave'
import { isBrowser } from '../../../../atoms/root'
import { navigate } from 'gatsby'
import { notEmpty } from '../../../../atoms/FormItems/validators/notEmpty'
import {
  submit,
  useInitialFormValuesFromParams
} from '../../../../atoms/useFormValues'
import { subscription } from '../vars'
import { useCatalogRegistrationModalContext } from '../hooks/useCatalogRegistrationModalContext'
import { useLocation } from '@reach/router'

const Form = styled(({ className, handleSubmit, restart, errors, values }) => {
  useInitialFormValuesFromParams([{ fieldName: 'promo' }])
  const { updateContext } = useCatalogRegistrationModalContext()

  return (
    <FormMain
      noValidate
      className={className}
      onSubmit={submit({
        handleSubmit,
        restart,
        errors,
        initialValues: values
      })}
    >
      <FormWrapWithoutButton>
        <FormHeader>
          <Title bold>Давайте знакомиться!</Title>
          <Paragraph>
            Расскажите немного о себе. Это поможет психологу лучше подготовиться
            к сессии.
          </Paragraph>
        </FormHeader>
        <FormItems>
          <Name
            label="Как к вам обращаться?"
            name={CatalogFieldNamesEnum.Name}
            placeholder="Имя или псевдоним"
            validate={notEmpty}
          />
          <Age
            label="Сколько вам лет?"
            name={CatalogFieldNamesEnum.Age}
            placeholder="Укажите возраст"
            validate={notEmpty}
          />
          <ControlTextArea
            required
            countCharacters={{ visible: true, max: 2000 }}
            id="aboutHelp"
            label="Запрос"
            name="aboutHelp"
            placeholder="Опишите своими словами..."
            validate={notEmpty('Поле обязательно для заполнения')}
          />
        </FormItems>
      </FormWrapWithoutButton>
      <ModalButtonGroup>
        <FormButton>Далее</FormButton>
      </ModalButtonGroup>
    </FormMain>
  )
})``

export const MeetStepForm = ({ onSubmit }) => {
  const { show, visible, hide } = usePopup()

  const [catalogFiltersData, setCatalogFiltersData] = useLocalStorage(
    CATALOG,
    {}
  )

  const { search } = useLocation()
  const searchObj = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  const [workingWithTeens, setWorkingWithTeens] = useState(
    searchObj['pt_is_work_with_teens']
  )

  const isWorkingWithTeens = searchObj['pt_is_work_with_teens']

  useEffect(() => {
    if (isBrowser && isWorkingWithTeens !== workingWithTeens) {
      setWorkingWithTeens(isWorkingWithTeens)
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkingWithTeens])

  const [isWorkingWithTeenagerPatients] = useSessionStorage(
    PSYCHOLOGIST_WORKS_WITH_TEENS,
    isWorkingWithTeens === 'true' ? true : false
  )
  const { formData } = useCatalogRegistrationModalContext()

  return (
    <>
      {visible && (
        <TeenagerPopup
          agreeText="Выбрать другого"
          declineText="Назад"
          handleAgree={() => {
            setCatalogFiltersData({
              ...INITIAL_CATALOG_FILTERS,
              ...catalogFiltersData,
              isWorkingWithTeenagerPatients: true
            })
            navigate(
              `/catalog/?${getCatalogQuery({
                ...INITIAL_CATALOG_FILTERS,
                ...catalogFiltersData,
                isWorkingWithTeenagerPatients: true
              })}`
            )
            hide()
          }}
          hide={hide}
          text="Давайте подберём другого психолога, который работает?"
          title="Этот психолог не работает с несовершеннолетними"
        />
      )}
      <FinalForm
        initialValues={{
          name: formData.name,
          age: ''
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, form: { restart }, errors, values }) => (
          <Form
            errors={errors}
            handleSubmit={(e) => {
              if (
                values.age >= 16 &&
                values.age < 18 &&
                !isWorkingWithTeenagerPatients
              ) {
                return show()
              }
              if (values.age < 16) {
                return navigate(`/`, {
                  state: {
                    showAgeLimitWarning: true
                  }
                })
              }
              return handleSubmit(e)
            }}
            restart={restart}
            values={values}
          />
        )}
        subscription={subscription}
        validate={() => {}}
      />
    </>
  )
}
