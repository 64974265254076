/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { CATALOG } from '../Information/usePsyCardStorage'
import { INITIAL_CATALOG_FILTERS } from '../CatalogContextProvider/vars'
import { PSYCHOLOGIST_WORKS_WITH_TEENS, size } from '../../constants'
import { TeenagerPopup } from '../CatalogFiltersModal/Fields/TeenagerPopup'
import { getCatalogQuery } from '../CatalogContextProvider/hooks/useQuerySave'
import { isBrowser } from '../../atoms/root'
import { navigate } from 'gatsby'
import { pick } from 'rambda'
import { useForm, useFormState } from 'react-final-form'
import {
  useLocalStorage,
  usePopup,
  useSessionStorage
} from '../../../youtalk-storybook/src/ui'
import { useLocation } from '@reach/router'

const ButtonWithErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

// eslint-disable-next-line sonarjs/cognitive-complexity
export const FormButton = styled(({ className, goBack }) => {
  const { search } = useLocation()
  const searchObj = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  const { pt_is_work_with_teens: isWorkingWithTeens, id: psychologistId } =
    searchObj

  const [workingWithTeens, setWorkingWithTeens] = useState(isWorkingWithTeens)

  useEffect(() => {
    if (isBrowser && isWorkingWithTeens !== workingWithTeens) {
      setWorkingWithTeens(isWorkingWithTeens)
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkingWithTeens])

  const [catalogFilters, setCatalogFiltersData] = useLocalStorage(CATALOG, {})
  const catalogFiltersData = {
    ...catalogFilters,
    psychologistID: psychologistId
  }

  const [loadingPromo, setLoadingPromo] = useState(false)
  const [isWorkingWithTeenagerPatients] = useSessionStorage(
    PSYCHOLOGIST_WORKS_WITH_TEENS,
    isWorkingWithTeens === 'true' ? true : false
  )
  const {
    mutators: { setFormStatePromocode },
    submit
  } = useForm()
  const {
    values: { promo, age },
    errors: { conditions, personal_data: personalData, ageLimit },
    submitting
  } = useFormState()
  const { show, visible, hide } = usePopup()
  const submitButtonProps = useMemo(
    () => ({
      onClick: async () => {
        if (
          psychologistId &&
          age < 18 &&
          age >= 16 &&
          !isWorkingWithTeenagerPatients
        ) {
          return show()
        }
        if (ageLimit) {
          return navigate(`/`, {
            state: {
              showAgeLimitWarning: true
            }
          })
        }
        if (!promo) return submit()
        setLoadingPromo(true)
        await setFormStatePromocode(promo)
        setLoadingPromo(false)
        return submit()
      },
      disabled: conditions || personalData,
      type: 'button'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      promo,
      conditions,
      personalData,
      ageLimit,
      age,
      isWorkingWithTeenagerPatients
    ]
  )

  return (
    <div className={className}>
      {visible && (
        <TeenagerPopup
          agreeText="Выбрать другого"
          declineText="Назад"
          handleAgree={() => {
            setCatalogFiltersData({
              ...INITIAL_CATALOG_FILTERS,
              ...catalogFiltersData,
              isWorkingWithTeenagerPatients: true
            })
            navigate(
              `/catalog/?${getCatalogQuery({
                ...INITIAL_CATALOG_FILTERS,
                ...catalogFiltersData,
                isWorkingWithTeenagerPatients: true
              })}`
            )
            hide()
          }}
          hide={hide}
          text="Давайте подберём другого психолога, который работает?"
          title="Этот психолог не работает с несовершеннолетними"
        />
      )}
      {goBack && (
        <Button.Grey onClick={goBack} width="fullScreen">
          Назад
        </Button.Grey>
      )}
      <ButtonWithErrorMessage>
        <Button.NewPrimary
          loading={submitting || loadingPromo}
          width="fullScreen"
          {...submitButtonProps}
          children="Отправить заявку"
        />
      </ButtonWithErrorMessage>
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    gap: 16px;
  }
`

const firstStepFieldsList = [
  'name',
  'age',
  'email',
  'tel',
  'personal_data',
  'conditions'
]

export const FormNextButton = ({ onClick }) => {
  const { errors, values } = useFormState()

  return (
    <Button.NewPrimary
      disabled={
        Boolean(Object.keys(pick(firstStepFieldsList, errors)).length) ||
        Object.values(values) >= 8
      }
      onClick={onClick}
      type="button"
    >
      Далее
    </Button.NewPrimary>
  )
}
