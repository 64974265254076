export const CatalogRegistrationStep = {
  MEET: 'MEET',
  IDENTITY: 'IDENTITY',
  CODE: 'CODE'
}
export const LOGIN_RESEND_TIME = 180
export const SESSION_STORAGE_KEY = 'auth'

export const RegistrationErrors = {
  USER_EXISTS: 'USER_EXISTS',
  UNDERAGE: 'UNDERAGE',
  INCORRECT_CODE: 'INCORRECT_CODE',
  INCOMPLETE_SESSION_DATA: 'INCOMPLETE_SESSION_DATA'
}

export const subscription = {
  errors: true,
  hasSubmitErrors: true,
  hasValidationErrors: true,
  invalid: true,
  pristine: true,
  submitting: true,
  values: true
}
