import { AUTH_RESULT } from '../organisms/RegistrationModals/vars'
import { REGISTRATION_WEBSITE_RESULT } from '../constants'
import { omit } from 'rambda'
import { useFormattedCatalogFiltersData } from '../organisms/RegistrationModals/hooks/useFormattedCatalogFiltersData'
import {
  useLocalStorage,
  useSessionStorage
} from '../../youtalk-storybook/src/ui'
import { usePsyCardStorage } from '../organisms/Information/usePsyCardStorage'

export function useRegisterResult() {
  const [_, setStorageValue] = useSessionStorage(
    REGISTRATION_WEBSITE_RESULT,
    {}
  )

  const [storedValue] = usePsyCardStorage()
  const formattedCatalogFiltersData =
    useFormattedCatalogFiltersData(storedValue)
  const [, setAuthResult] = useLocalStorage(AUTH_RESULT, {})

  const setRegisterResult = (params = {}) => {
    const { jwt, role } = params
    if (jwt && role) {
      setAuthResult({ jwt, role })
    }
    setStorageValue({
      ...params,
      searchPreferences: {
        ...omit(['age', 'name', 'sourceId'], formattedCatalogFiltersData)
      }
    })
  }

  return { setRegisterResult }
}
