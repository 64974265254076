import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  AgreementText,
  FormHeader,
  FormItems,
  FormMain,
  FormWrapWithoutButton,
  ModalButtonGroup,
  Paragraph,
  SmsInfoBlock,
  SmsInfoSvg,
  SmsInfoText,
  Title
} from '../../index.styles'
import { Form as FinalForm } from 'react-final-form'
import { FormButton } from '../../FormButton'
import { InternationalTelephone } from '../../../Form/Input'
import { PsychologistPlate } from '../../../../atoms/PsychologistPlate'
import { documents } from '../../../../../static/db.json'
import {
  submit,
  useInitialFormValuesAfterDOMLoad,
  useInitialFormValuesFromParams
} from '../../../../atoms/useFormValues'
import { subscription } from '../vars'
import { useRegistrationModalContext } from '../hooks/useRegistrationModalContext'

const Form = styled(
  ({ className, handleSubmit, restart, errors, values, psychologist }) => {
    useInitialFormValuesFromParams([{ fieldName: 'promo' }])
    useInitialFormValuesAfterDOMLoad([{ fieldName: 'tel', value: '+7' }])
    const { updateContext } = useRegistrationModalContext()
    const onChangeCountry = useCallback(
      (country) => updateContext({ country }),
      []
    )

    return (
      <FormMain
        noValidate
        className={className}
        onSubmit={submit({
          handleSubmit,
          restart,
          errors,
          initialValues: values
        })}
      >
        <FormWrapWithoutButton>
          <FormHeader>
            <PsychologistPlate psychologist={psychologist} />
            <Title bold>Регистрация</Title>
            <Paragraph>
              Создайте аккаунт, чтобы начать заниматься с психологом
            </Paragraph>
          </FormHeader>
          <FormItems>
            <InternationalTelephone onChangeCountry={onChangeCountry} />
            <ModalButtonGroup>
              <FormButton>Зарегистрироваться</FormButton>
            </ModalButtonGroup>
          </FormItems>
        </FormWrapWithoutButton>
        <SmsInfoBlock>
          <SmsInfoSvg />
          <SmsInfoText>
            Никаких звонков, только SMS о статусе сессий. А ещё на этот номер
            напишет выбранный психолог в мессенджер
          </SmsInfoText>
        </SmsInfoBlock>
        <AgreementText>
          {'Отправляя заявку, я соглашаюсь на '}
          <a href={documents.urlPolicy} rel="noreferrer" target="_blank">
            обработку персональных данных
          </a>
          {' и с '}
          <a href={documents.urlAgreement} rel="noreferrer" target="_blank">
            условиями лицензионного соглашения
          </a>
        </AgreementText>
      </FormMain>
    )
  }
)``

export const IdentityStepForm = ({ onSubmit }) => {
  const { formData, psychologist } = useRegistrationModalContext()

  return (
    <FinalForm
      initialValues={{
        agreement: true,
        tel: formData.tel
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { restart }, errors, values }) => (
        <Form
          errors={errors}
          handleSubmit={handleSubmit}
          psychologist={psychologist}
          restart={restart}
          values={values}
        />
      )}
      subscription={subscription}
      validate={() => {}}
    />
  )
}
