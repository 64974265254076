/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
/* eslint-disable react-func/max-lines-per-function */
import dayjs from 'dayjs'
import { AvatarSizesEnum } from '../../../atoms/Avatar'
import { WizardSessionFormat } from '../../../atoms/mappers/wizard/enums/sessionFormat'
import { createActualPriceString, createMinPriceString } from './utils'
import { getBadgesToShow } from '../../../atoms/badgesToShow'
import { getCatalogQuery } from '../../CatalogContextProvider/hooks/useQuerySave'
import {
  getExperienceWithText,
  getYearsWithText
} from '../../../atoms/ageParser'
import { languageMap } from '../../../atoms/mappers/wizard/languageMap'
import { selectDeviceSize } from '../../../state/reducers/deviceSlice'
import { useCatalogContext } from '../../CatalogContextProvider/hooks/useCatalogContext'
import { useCheckIsWizard } from '../../../hooks/useCheckIsWizard'
import { useLocalStorage } from '../../../../youtalk-storybook/src/ui'
import { useMemo } from 'react'
import { usePsychologistCardHref } from '../../../atoms/usePsychologistHref'
import { useSelector } from 'react-redux'
import { wizardValuesToValidSessionTypeEnum } from '../../../atoms/mappers/wizard/utils'

// eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
export const usePropsByContext = ({ psychologist }) => {
  const [filters] = useCatalogContext().storage
  const {
    state: { page, state }
  } = useCatalogContext()
  const windowWidth = useSelector(selectDeviceSize)
  const { name, english } = psychologist

  const href = usePsychologistCardHref(
    psychologist.id,
    getCatalogQuery({ ...filters, source: page.source })
  )
  const age = getYearsWithText(psychologist.birthdayDate)
  const experience = getExperienceWithText(psychologist.startOfPractice)
  const professions = psychologist.professions
    .map(({ professionName }) => professionName)
    .slice(0, 2)

  const nearestSlot = useMemo(() => {
    if (!psychologist.nearestSlot) {
      return {
        display: 'По запросу'
      }
    }
    const datetime = dayjs(psychologist.nearestSlot).format('YYYY-MM-DDTHH:mm')
    const display = dayjs(psychologist.nearestSlot).format('D MMM, HH:mm')

    const isUrgent =
      dayjs(psychologist.nearestSlot).diff(dayjs(new Date()), 'hours') < 27
    const isToday = dayjs(psychologist.nearestSlot).isSame(
      dayjs(new Date()),
      'days'
    )

    if (isToday) {
      return {
        datetime,
        display: dayjs(psychologist.nearestSlot).format('Сегодня, HH:mm'),
        isUrgent
      }
    }

    return {
      datetime,
      display,
      isUrgent
    }
  }, [state])

  const getPrice = (filters) => {
    const minPrice = createMinPriceString(psychologist.rates)
    if (filters.language) {
      const filtersProps = {
        date: nearestSlot.datetime ?? new Date(),
        language: languageMap[filters.language],
        sessionType: wizardValuesToValidSessionTypeEnum({
          therapyType: filters.client,
          sessionFormat: WizardSessionFormat.Video
        })
      }
      const actualPrice = createActualPriceString({
        rates: psychologist.rates,
        ...filtersProps
      })

      return actualPrice ?? minPrice
    }
    return minPrice
  }

  const price = useMemo(() => getPrice(filters), [state])

  const workAreas = useMemo(
    () =>
      psychologist.workAreas
        .map((workArea) => {
          if (filters.workAreas?.includes(workArea.id)) {
            return { ...workArea, checked: true }
          }
          return { ...workArea, checked: false }
        })
        .sort((workArea) => (workArea.checked ? -1 : 1))
        .slice(0, 3),
    [state.enabled]
  )

  const isMedium = windowWidth > 1439 || windowWidth <= 1024

  const avatarProps = {
    alt: `аватар для психолога ${name}`,
    english,
    href,
    photoUrl: psychologist.photoUrl,
    videoURL: psychologist.videoURL,
    source: 'listing',
    name: `${psychologist?.name} ${psychologist?.surname}`,
    size: isMedium ? AvatarSizesEnum.Medium : AvatarSizesEnum.Small,
    style: isMedium ? {} : { margin: '11px 0' }
  }

  const moreDetailsButtonProps = {
    href
  }

  const badges = getBadgesToShow(psychologist.badges)

  return {
    name,
    age,
    experience,
    professions,
    nearestSlot,
    price,
    workAreas,
    avatarProps,
    moreDetailsButtonProps,
    badges,
    videoURL: psychologist?.videoURL,
    reviews: psychologist?.reviews
  }
}

export const usePropsByPsychologist = ({ psychologist }) => {
  const windowWidth = useSelector(selectDeviceSize)
  const { name, english } = psychologist

  const href = usePsychologistCardHref(psychologist.id, '')
  const age = getYearsWithText(psychologist.birthday_date)
  const experience = getExperienceWithText(psychologist.start_of_practice)
  const professions = psychologist.professions
    .map(({ profession_name }) => profession_name)
    .slice(0, 2)
  const nearestSlot = useMemo(() => {
    if (!psychologist.nearest_slot) {
      return {
        display: 'По запросу'
      }
    }
    const datetime = dayjs(psychologist.nearest_slot).format('YYYY-MM-DD')
    const display = dayjs(psychologist.nearest_slot).format('D MMMM')

    const isUrgent =
      dayjs(psychologist.nearest_slot).diff(dayjs(new Date()), 'hours') < 27
    const isToday = dayjs(psychologist.nearest_slot).isSame(
      dayjs(new Date()),
      'days'
    )

    if (isToday) {
      return {
        datetime,
        display: dayjs(psychologist.nearestSlot).format('Сегодня, HH:mm'),
        isUrgent
      }
    }

    return {
      datetime,
      display,
      isUrgent
    }
  }, [])

  const price = createMinPriceString(psychologist.rates)

  const workAreas = useMemo(
    () =>
      psychologist.work_areas
        .map((workArea) => ({
          ...workArea,
          name: workArea.tag,
          checked: true
        }))
        .sort((workArea) => (workArea.checked ? -1 : 1))
        .slice(0, 3),
    []
  )

  const isMedium = windowWidth > 1439 || windowWidth <= 1024

  const avatarProps = {
    alt: `аватар для психолога ${name}`,
    href,
    english,
    photoUrl: psychologist.photoUrl,
    size: isMedium ? AvatarSizesEnum.Medium : AvatarSizesEnum.Small,
    style: isMedium ? {} : { margin: '11px 0' }
  }

  const badges = getBadgesToShow(psychologist.badges)

  const moreDetailsButtonProps = {
    href
  }

  return {
    name,
    age,
    experience,
    professions,
    nearestSlot,
    price,
    workAreas,
    avatarProps,
    moreDetailsButtonProps,
    badges,
    reviews: psychologist?.reviews
  }
}

export const usePropsByAdditionalPsychologist = ({ psychologist }) => {
  const isWizard = useCheckIsWizard()
  const [catalogFilters] = useLocalStorage('catalog', {})
  const [wizardFilters] = useLocalStorage('wizard', {})
  const filters = isWizard ? wizardFilters : catalogFilters
  const windowWidth = useSelector(selectDeviceSize)
  const { name, english } = psychologist

  const href = usePsychologistCardHref(psychologist.id, '')
  const age = getYearsWithText(psychologist.birthdayDate)
  const experience = getExperienceWithText(psychologist.startOfPractice)
  const professions = psychologist.professions
    .map(({ professionName }) => professionName)
    .slice(0, 2)
  const nearestSlot = useMemo(() => {
    if (!psychologist.nearestSlot) {
      return {
        display: 'По запросу'
      }
    }
    const datetime = dayjs(psychologist.nearestSlot).format('YYYY-MM-DD')
    const display = dayjs(psychologist.nearestSlot).format('D MMMM')
    const now = new Date()

    const isUrgent =
      dayjs(psychologist.nearestSlot).diff(dayjs(now), 'hours') < 27
    const isToday = dayjs(psychologist.nearestSlot).isSame(
      dayjs(new Date()),
      'days'
    )

    if (isToday) {
      return {
        datetime,
        display: dayjs(psychologist.nearestSlot).format('Сегодня, HH:mm'),
        isUrgent
      }
    }

    return {
      datetime,
      display,
      isUrgent
    }
  }, [])

  const getPrice = (filters) => {
    const minPrice = createMinPriceString(psychologist.rates)
    if (filters.language) {
      const filtersProps = {
        date: nearestSlot.datetime ?? new Date(),
        language: languageMap[filters.language],
        sessionType: wizardValuesToValidSessionTypeEnum({
          therapyType: filters.client,
          sessionFormat: WizardSessionFormat.Video
        })
      }
      const actualPrice = createActualPriceString({
        rates: psychologist.rates,
        ...filtersProps
      })

      return actualPrice ?? minPrice
    }
    return minPrice
  }

  const price = getPrice(filters)

  const workAreas = useMemo(
    () =>
      psychologist.workAreas
        .map((workArea) => {
          if (filters.workAreas?.includes(workArea.id)) {
            return { ...workArea, checked: true }
          }
          return { ...workArea, checked: false }
        })
        .sort((workArea) => (workArea.checked ? -1 : 1))
        .slice(0, 3),
    []
  )

  const isMedium = windowWidth > 1439 || windowWidth <= 1024

  const avatarProps = {
    alt: `аватар для психолога ${name}`,
    href,
    english,
    photoUrl: psychologist.photoUrl,
    size: isMedium ? AvatarSizesEnum.Medium : AvatarSizesEnum.Small,
    style: isMedium ? {} : { margin: '11px 0' }
  }

  const badges = getBadgesToShow(psychologist.badges)

  const moreDetailsButtonProps = {
    href
  }

  return {
    name,
    age,
    experience,
    professions,
    nearestSlot,
    price,
    workAreas,
    avatarProps,
    moreDetailsButtonProps,
    badges,
    reviews: psychologist?.reviews
  }
}

export const getPropsMapper = (isProfileBlock) => {
  if (isProfileBlock) {
    return usePropsByAdditionalPsychologist
  }

  return usePropsByPsychologist
}
