import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Avatar } from './Avatar'
import { ReactComponent as CalendarIcon } from './Icons/img/calendar.svg'
import { Text } from './Text'
import { capitalize } from './capitalize'
import { useFormattedCatalogFiltersData } from '../organisms/RegistrationModals/hooks/useFormattedCatalogFiltersData'
import { usePsyCardStorage } from '../organisms/Information/usePsyCardStorage'

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  justify-content: center;
`

const SessionTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`

export const PsychologistPlate = styled(({ className, psychologist }) => {
  const [storedValue] = usePsyCardStorage()

  const filtersData = useFormattedCatalogFiltersData(storedValue)
  return psychologist ? (
    <div className={className}>
      <Avatar photoUrl={psychologist.photoUrl} size={56} tooltipSize="small" />
      <TextBox>
        <Text.Large bold>{psychologist.name}</Text.Large>
        <SessionTime>
          <CalendarIcon height={24} width={24} />
          <Text.Average>
            {`${capitalize(
              dayjs(filtersData?.sessionDateTime).format('dd, D MMMM в HH:mm')
            )} (GMT`}
            &nbsp;{dayjs(filtersData?.sessionDateTime).format('Z') + ')'}
          </Text.Average>
        </SessionTime>
      </TextBox>
    </div>
  ) : null
})`
  display: flex;
  padding: 24px;
  padding-right: 8px;
  margin-bottom: 16px;
  gap: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 16px 30px -12px #e7f1fc;
`
