import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Avatar } from '../../atoms/Avatar'
import { ReactComponent as FireworksSVG } from './img/fireworks.svg'
import { Text } from '../../atoms/Text'
import { getYearsWithText } from '../../atoms/ageParser'
import { size } from '../../constants'
import { useGetPsychologist } from './query'

const Name = styled(Text.Medium)`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`

const ProfessionWithAges = styled(Text.Medium)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const PlateGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const FireWorks = styled(FireworksSVG)``

const WithAnimate = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition: all 0.33s ease-in 0.1s;
  overflow: hidden;

  ${({ loading }) =>
    !loading &&
    css`
      grid-template-rows: 1fr;
      opacity: 1;
    `}
`

export const PsychologistPlate = styled(
  ({ className, id, data: propsData = null }) => {
    const { data, loading } = useGetPsychologist(
      {
        variables: {
          id
        }
      },
      {
        skip: Boolean(id)
      }
    )
    const psychologist = useMemo(
      () => propsData ?? data?.psychologistsCatalogItem,
      [propsData, data]
    )

    const professions = useMemo(
      () =>
        psychologist?.professions
          ? psychologist.professions
              .map(({ professionName }, index) =>
                index === 0 ? professionName : professionName.toLowerCase()
              )
              .join(', ')
          : null,
      [psychologist?.professions]
    )

    const content = useMemo(() => {
      const age = getYearsWithText(
        psychologist?.birthdayDate ?? psychologist?.birthdayDate
      )
      return !age ? professions : professions ? `${professions} · ${age}` : age
    }, [psychologist?.birthdayDate, psychologist?.birthdayDate, professions])

    return (
      <WithAnimate loading={loading || !psychologist}>
        <a
          className={className}
          href={`${process.env.GATSBY_SITEURL}/psychologist/${psychologist?.id}`}
          rel="noreferrer"
          target="_blank"
        >
          {psychologist?.photoUrl && (
            <Avatar
              english={psychologist?.english}
              photoUrl={psychologist.photoUrl}
              size={56}
              tooltipSize="small"
            />
          )}
          <PlateGrid>
            <FireWorks />
            <Name>{psychologist?.name}</Name>
            {content && <ProfessionWithAges>{content}</ProfessionWithAges>}
          </PlateGrid>
        </a>
      </WithAnimate>
    )
  }
)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: #f3f7fe;
  padding: 24px;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;

  &,
  &:active,
  &:hover {
    text-decoration: none;
    color: #333;
  }

  @media (max-width: ${size.sm}) {
    padding: 16px;
    overflow: hidden;
  }

  & ${PlateGrid} {
    margin-left: 24px;

    @media (max-width: ${size.sm}) {
      margin-left: 16px;
    }
  }

  & ${Name} {
    white-space: nowrap;
    z-index: 1;

    @media (max-width: ${size.xs}) {
      font-size: 16px;
      line-height: 24px;
      white-space: normal;
    }
  }

  & ${FireWorks} {
    position: absolute;
    top: 0;
    right: 6px;
    z-index: 0;

    @media (max-width: ${size.md}) {
      right: -12px;
    }

    ${({ mini }) =>
      mini &&
      css`
        right: -58px;
        top: 6px;

        @media (max-width: ${size.md}) {
          right: -60px;
        }

        @media (max-width: ${size.sm}) {
          top: 0;
        }
      `}

    @media (max-width: ${size.sm}) {
      right: -102px;
    }

    @media (max-width: ${size.xs}) {
      right: -132px;
      top: -46px;
    }
  }
`
