import { gql, useQuery } from '@apollo/client'
import { isBrowser } from '../../../atoms/root'

const GET_FILTERED_PSYCHOLOGISTS = gql`
  query psychologistsCatalog(
    $options: PsychologistsCatalogOptions
    $filter: PsychologistsCatalogFilter
  ) {
    psychologistsCatalog(options: $options, filter: $filter) {
      fullyMatch
      total
      items {
        id
        name
        userID
        workAreas {
          name
          id
        }
        workApproaches {
          name
          id
        }
        nonWorkingAreas {
          name
          id
        }
        professions {
          professionName
          id
        }
        photoUrl
        video
        english
        individual
        syncMessages
        family
        messages
        audio
        friendliness
        gender
        surname
        features
        birthdayDate
        startOfPractice
        nearestSlot
        isActive
        isAvailable
        visibility
        rates {
          startDate
          finishDate
          prices {
            language
            type
            price
          }
        }
        badges {
          id
          name
          description
          priority
          isActive
        }
        reviews {
          id
          clientAge
          clientName
          text
          tags
        }
        videoURL
      }
    }
  }
`

export const useGetFilteredPsychologists = (input) =>
  useQuery(GET_FILTERED_PSYCHOLOGISTS, {
    fetchPolicy: 'network-only',
    skip: !isBrowser || input.skip,
    ...input
  })
